import { useState, useMemo } from 'react';

const useBoolean = defaultValue => {
  const [value, setValue] = useState(defaultValue);

  const setters = useMemo(() => {
    return {
      on() {
        setValue(true);
      },
      off() {
        setValue(false);
      },
      toggle() {
        setValue(value => !value);
      },
    };
  }, [setValue]);

  return [value, setters];
};

export default useBoolean;
