import React, { useContext } from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';

import { AuthContext } from '@context/AuthContext';
import profilePlaceholder from '@styles/img/profile.jpg';
import NavigationLogo from './NavigationLogo';

const navLinks = [
  {
    label: 'Balance',
    path: '/',
  },
  {
    label: 'Programados',
    path: '/programmed',
  },
  {
    label: 'Movimientos',
    path: '/movements',
  },
];

const NavItem = ({ path, children }) => {
  const location = useLocation();

  let active = location.pathname === path;

  if (path !== '/') {
    active = location.pathname.startsWith(path);
  }

  return (
    <li className={clsx({ active })}>
      <Link to={path} className="row">
        <span>{children}</span>
      </Link>
    </li>
  );
};

const Navigation = () => {
  const { userInfo } = useContext(AuthContext);

  const fullName = `${userInfo.firstName} ${userInfo.lastName}`;

  return (
    <nav className="row navigation">
      <article className="center_content">
        <NavigationLogo />
        <Link to="/profile">
          <div className="user_profile_card">
            <div className="user_profile_image">
              <img src={profilePlaceholder} alt={fullName} />
            </div>
            <p className="user_profile_name">{fullName}</p>
          </div>
        </Link>
      </article>
      <article className="center_content">
        <ul className="content_links">
          {navLinks.map(link => (
            <NavItem key={link.path} path={link.path}>
              {link.label}
            </NavItem>
          ))}
        </ul>
      </article>
    </nav>
  );
};

export default Navigation;
