import React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Button = ({ children, onClick, loading, className, disabled, ...props }) => {
  const handleLinkClick = event => {
    event.preventDefault();
    if (!loading && !disabled) {
      onClick();
    }
  };

  let disabledClassName = 'disabled_full_btn';

  if (className?.split(' ').includes('border')) {
    disabledClassName = 'disabled_border_btn';
  }

  return (
    <a
      {...props}
      href="#/"
      className={clsx(className, {
        loading_btn: !!loading,
        [disabledClassName]: !!disabled,
      })}
      onClick={handleLinkClick}
    >
      {loading ? (
        <span>
          <FontAwesomeIcon icon={faSpinner} spin />
        </span>
      ) : null}
      {children}
    </a>
  );
};

export default Button;
