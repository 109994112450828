import React, { createContext, useState } from 'react';
import { queryCache, useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import fetchUserInfo from '@operations/queries/fetchUserInfo';
import logoutMutation from '@operations/mutations/logout';

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);

  const userId = localStorage.getItem('userId');

  useQuery(['userInfo', { userId, extended: true }], fetchUserInfo, {
    onSuccess: data => {
      setUserInfo(data);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const [commitLogout] = useMutation(logoutMutation);

  const logout = async () => {
    await commitLogout();
    delete axios.defaults.headers.authorization;
    localStorage.removeItem('userId');
    localStorage.removeItem('authToken');
    queryCache.clear();
    setUserInfo(null);
    history.push('/login');
  };

  const login = (user, token) => {
    axios.defaults.headers.authorization = `Bearer ${token}`;
    localStorage.setItem('userId', user.userId);
    localStorage.setItem('authToken', token);
    setUserInfo(user);
    history.push('/');
  };

  return (
    <Provider
      value={{
        isAuthenticated: !!userInfo,
        logout,
        login,
        userInfo,
        loading,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
