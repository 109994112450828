import ReactGA from 'react-ga';

function init() {
  if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    // Enable debug mode on the local development environment
    const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, { debug: isDev });
  }
}

function sendEvent(payload) {
  ReactGA.event(payload);
}

function sendPageView(path) {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
}

export default {
  init,
  sendEvent,
  sendPageView,
};
