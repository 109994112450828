import React from 'react';

import Toast from '@components/Layout/Toast';
import Navigation from '@components/Layout/Navigation';

const AppShell = ({ children }) => {
  return (
    <div className="row dashboard">
      <Toast />
      <Navigation />
      {children}
    </div>
  );
};

export default AppShell;
