import React, { useContext, useEffect } from 'react';
import clsx from 'clsx';
import { useQuery } from 'react-query';

import Button from '@components/UI/Button';
import useBoolean from '@utils/useBoolean';
import { format } from '@utils/formatNumber';
import { AuthContext } from '@context/AuthContext';
import fetchPendingRequest from '@operations/queries/fetchPendingRequest';

const Toast = () => {
  const { userInfo } = useContext(AuthContext);
  const [show, { off: showOff, on: showOn }] = useBoolean(false);

  const { data, error } = useQuery(['pendingRequest', { userId: userInfo.userId }], fetchPendingRequest, {
    enabled: userInfo.userId,
  });

  useEffect(() => {
    if (data?.length || error) {
      showOn();
    }
  }, [data, error, showOn]);

  if (error?.response?.data?.errorCode === 3009) {
    return (
      <div className={clsx('content_toast', { show })}>
        <div className="center_content">
          <span className="text">{error.response.data.showMessage.ES}</span>
          <Button className="btn_close_toast" onClick={showOff}>
            Close
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={clsx('content_toast', { show })}>
      <div className="center_content">
        <span className="text">Hay una solicitud en proceso por:</span>
        <span className="amount">$ {data?.length && format(data[0].amount)}</span>
        <Button className="btn_close_toast" onClick={showOff}>
          Close
        </Button>
      </div>
    </div>
  );
};

export default Toast;
