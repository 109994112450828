import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import analytics from './analytics';

export default function useGoogleAnalytics() {
  const [initialized, setInitialized] = useState(false);
  const location = useLocation();

  useEffect(() => {
    analytics.init();
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      const currentPath = location.pathname + location.search;
      analytics.sendPageView(currentPath);
    }
  }, [location, initialized]);
}
