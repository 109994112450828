import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import Skeleton from 'react-loading-skeleton';

import { AuthContext } from '@context/AuthContext';
import fetchBusiness from '@operations/queries/fetchBusiness';

const NavigationLogo = () => {
  const { userInfo } = useContext(AuthContext);

  const { data, isLoading } = useQuery(['business', { businessId: userInfo.businessId }], fetchBusiness);

  if (isLoading) {
    return (
      <Link to="/" className="logo" style={{ background: 'none' }}>
        <Skeleton width={200} height={50} />
      </Link>
    );
  }

  if (data?.logo) {
    return (
      <Link to="/" className="logo" style={{ background: 'none' }}>
        <img
          src={`https://s3.amazonaws.com/${process.env.REACT_APP_AWS_BUCKET}${data.logo}`}
          alt={`${data?.name} logo`}
          style={{ height: '100%' }}
        />
      </Link>
    );
  }

  return (
    <Link to="/" className="logo">
      SueldosYa
    </Link>
  );
};

export default NavigationLogo;
