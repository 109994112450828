import React, { useContext, lazy, Suspense } from 'react';
import { ReactQueryConfigProvider } from 'react-query';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useClearCache } from 'react-clear-cache';

import { AuthProvider, AuthContext } from '@context/AuthContext';
import Spinner from '@components/UI/Spinner';
import useGoogleAnalytics from '@utils/useGoogleAnalytics';
import AppShell from './AppShell';

const Balance = lazy(() => import('@pages/Balance'));
const Profile = lazy(() => import('@pages/Profile'));
const Movements = lazy(() => import('@pages/Movements'));
const Onboarding = lazy(() => import('@pages/Onboarding'));
const Login = lazy(() => import('@pages/Login'));
const ResetPassword = lazy(() => import('@pages/ResetPassword'));
const ForgotPassword = lazy(() => import('@pages/ForgotPassword'));
const ProgrammedRequests = lazy(() => import('@pages/ProgrammedRequests'));
const ProgrammedRequestWizard = lazy(() => import('@pages/ProgrammedRequestWizard'));

const LoadingFallback = () => (
  <div className="full-page-center">
    <Spinner />
  </div>
);

const UnauthenticatedRoutes = () => {
  const { isAuthenticated } = useContext(AuthContext);

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <Switch>
       <Route exact path="/onboarding">
        <Onboarding />
      </Route>
      <Route path="/onboarding/:invitationToken">
        <Onboarding />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/restore-password">
        <ResetPassword />
      </Route>
      <Route path="*">
        <h1>404: Not Found</h1>
      </Route>
    </Switch>
  );
};

const AuthenticatedRoute = ({ children, ...rest }) => {
  const { isAuthenticated } = useContext(AuthContext);

  return <Route {...rest} render={() => (isAuthenticated ? children : <Redirect to="/login" />)} />;
};

const AppRoutes = () => {
  useGoogleAnalytics();

  const { loading } = useContext(AuthContext);

  if (loading) {
    return <LoadingFallback />;
  }

  return (
    <Suspense fallback={<LoadingFallback />}>
      <Switch>
        <AuthenticatedRoute path="/profile">
          <AppShell>
            <Profile />
          </AppShell>
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/programmed/create">
          <AppShell>
            <ProgrammedRequestWizard />
          </AppShell>
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/programmed/:requestId">
          <AppShell>
            <ProgrammedRequestWizard />
          </AppShell>
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/programmed">
          <AppShell>
            <ProgrammedRequests />
          </AppShell>
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/movements">
          <AppShell>
            <Movements />
          </AppShell>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/">
          <AppShell>
            <Balance />
          </AppShell>
        </AuthenticatedRoute>
        <UnauthenticatedRoutes />
      </Switch>
    </Suspense>
  );
};

function App() {
  useClearCache({ auto: true, basePath: process.env.PUBLIC_URL });

  return (
    <ReactQueryConfigProvider config={{ refetchAllOnWindowFocus: false }}>
      <Router basename={process.env.PUBLIC_URL}>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </Router>
    </ReactQueryConfigProvider>
  );
}

export default App;
