import React from 'react';
import ReactDOM from 'react-dom';
import { ReactQueryConfigProvider } from 'react-query';
import axios from 'axios';
import '@styles/css/frontend/html-reset.css';
import '@styles/css/frontend/styles.css';
import '@styles/css/frontend/custom.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import useDeferredPrompt from '@utils/useDeferredPrompt';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

const token = localStorage.getItem('authToken');

if (token) {
  axios.defaults.headers.authorization = `Bearer ${token}`;
}

const config = {
  queries: {
    refetchOnWindowFocus: false,
    retry: false,
    onError: error => {
      if (token && [1001, 1004, 3009].includes(error.response.data?.errorCode)) {
        localStorage.removeItem('userId');
        localStorage.removeItem('authToken');
        window.location = process.env.PUBLIC_URL + '/login';
      }
    },
  },
};

ReactDOM.render(
  <ReactQueryConfigProvider config={config}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ReactQueryConfigProvider>,
  document.getElementById('root')
);

window.addEventListener('beforeinstallprompt', e => {
  // Prevent the mini-infobar from appearing on mobile
  e.preventDefault();
  // Stash the event so it can be triggered later.
  useDeferredPrompt.setState({ androidDeferredPrompt: e });
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
